import type { Dayjs } from 'dayjs';

export const GANTT_SCROLLABLE_WIDTH = 11521;
export const GANTT_CELL_WIDTH = 64;
export const GANTT_HEADER_HEIGHT = 42;
export const GANTT_CHART_PADDING = 30;
export const GANTT_CHART_BUFFER_MONTHS = 12; // Number of months to buffer the start and end dates by
export const EMPTY_OBJECT_MESSAGE = 'This object is empty.\nAdd milestones or nest items to fill it.';
export const GANTT_EMPTY_OBJECT_WIDTH = 2;
export const GANTT_EMPTY_TASK_WIDTH = 0;

export interface AdditionalObjectProps {
  calculatedStartDate: Dayjs | string;
  calculatedTargetDate: Dayjs | string;
  // children: ModularObjectTypes;
}

export enum ScheduleView {
  List = 'builds',
  Gantt = 'gantt',
  Drivers = 'drivers',
  Workload = 'workload',
}

export enum TimelineZoomLevel {
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Quarter = 'quarter',
}

export enum TimelineColumnValues {
  Week = 'day',
  Month = 'week',
  Year = 'quarter',
  Quarter = 'month',
}
