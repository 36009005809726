import type { Filters } from '@/__generated__/types';
import { type FilterQueryParams, useQueryParams } from '@/hooks/useQueryParams';
import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { GetCriticalPathQuery } from '../modals/ObjectSelectionModal/getCriticalPath.generated';
import type { GetAllTasksQueryVariables } from './queries/getAllTasks.generated';
import { type GetFilterByIdQuery, useGetFilterByIdQuery } from './queries/getFilterById.generated';
import type { GetTopLevelModularObjectsQueryVariables } from './queries/getTopLevelModularObjects.generated';

export const getDefaultFilterOptions = () => ({
  status: [] as string[],
  owner: [] as string[],
  assignee: [] as string[],
  visibility: [] as string[],
  permission: [] as string[],
  parent: [] as string[],
  build: [] as string[],
  driver: [] as string[],
  team: [] as string[],
});

interface FilterContextProps {
  toggleFilterValue: (type: string, filterValue: string, cb?: () => void) => void;
  selectedFilters: ReturnType<typeof getDefaultFilterOptions>;
  variables: GetTopLevelModularObjectsQueryVariables | GetAllTasksQueryVariables;
  resetFilters: () => void;
  hasSelectedFilters: boolean;
  setCriticalPath: (criticalPath: GetCriticalPathQuery['getCriticalPath']) => void;
  isCriticalPathShowing: boolean;
  isFlattened: boolean;
  criticalPath?: GetCriticalPathQuery['getCriticalPath'];
  clearCriticalPath: () => void;
  isObjOnCriticalPath: (objId: string) => boolean;
  selectedSavedFilter: GetFilterByIdQuery['getFilter'];
  setSelectedSavedFilter: React.Dispatch<React.SetStateAction<GetFilterByIdQuery['getFilter'] | null>>;
}
export const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export function useFilterContext (): FilterContextProps {
  const context = useContext(FilterContext);

  if (!context) throw new Error('FilterContext must be used within a FilterContextProvider');

  return context;
}

export const FilterContextProvider = ({ children }: PropsWithChildren) => {
  const { queryParams, updateUrlParams } = useQueryParams<FilterQueryParams>();
  const { filter: queryFilter } = queryParams;

  const { data } = useGetFilterByIdQuery({
    variables: { id: queryFilter },
    skip: !queryFilter,
  });

  const [selectedSavedFilter, setSelectedSavedFilter] = useState<GetFilterByIdQuery['getFilter']>(null);
  const [selectedFilters, setSelectedFilters] = useState(getDefaultFilterOptions());
  const [criticalPath, setCriticalPath] = useState<GetCriticalPathQuery['getCriticalPath']>([]);

  // Remove filter if the query param is removed
  useEffect(() => {
    if (!queryFilter && selectedSavedFilter) {
      setSelectedSavedFilter(null);
      setSelectedFilters(getDefaultFilterOptions());
    }
  }, [queryFilter, selectedSavedFilter]);

  // Update the selectedSavedFilter with the new data if it changes, I would have done this through the onCompleted callback but that doesn't
  // get retriggered when the query is refetched
  useEffect(() => {
    if (data?.getFilter) {
      setSelectedFilters(data.getFilter.data as ReturnType<typeof getDefaultFilterOptions>);
      setSelectedSavedFilter(data.getFilter);
    }
  }, [data]);

  const isCriticalPathShowing = criticalPath?.length > 0;

  const toggleFilterValue = useCallback((type: string, filterValue: string, cb: () => void): void => {
    // This could be the single source of truth for the selected filters, look into a way to remove the `variables` state and use this instead
    const newSelectedFilters = selectedFilters[type]?.includes(filterValue) // Checks if filter is already selected
      ? selectedFilters[type]?.filter((filter) => filter !== filterValue) // Gets all filters except the one that was clicked, removing the already selected filter
      : [...(selectedFilters[type] ?? []), filterValue]; // Adds the filter to the selected filters

    setSelectedFilters({ ...selectedFilters, [type]: newSelectedFilters });

    // Callback for things like collapsing all the expanded objects on the schedule
    if (cb) {
      cb();
    }
  }, [selectedFilters]);

  const variables = useMemo(() => {
    return Object.entries(selectedFilters).reduce((acc, [type, value]) => {
      if (Array.isArray(value)) {
        const ids = value.map((v) => ['owner', 'assignee', 'parent', 'team'].includes(type) ? v.split(':')[1] : v);

        if (ids.length > 0) {
          acc.filters = { ...acc.filters, [type]: [...acc.filters?.[type] || []].concat(ids) };
        }
      }

      return acc;
    }, { filters: {} as Filters });
  }, [
    selectedFilters,
  ]);

  const resetFilters = useCallback(() => {
    setSelectedFilters(getDefaultFilterOptions());
    setCriticalPath([]);
    if (queryParams?.filter) {
      const { filter: _, ...rest } = queryParams;
      updateUrlParams(rest);
    }
  }, [updateUrlParams, queryParams]);

  const clearCriticalPath = useCallback(() => {
    setCriticalPath([]);
  }, []);

  const isObjOnCriticalPath = useCallback((objId: string) => {
    if (!isCriticalPathShowing) return false;
    return criticalPath.some((obj) => obj.id === objId);
  }, [criticalPath, isCriticalPathShowing]);

  // Cannot memo this bc toggleFilterValue is mutating `selectedFilters` and causes it to not show its value as different as a result
  const hasSelectedFilters = Object.keys(selectedFilters).some((filter) => {
    return selectedFilters[filter].length > 0;
  }) || isCriticalPathShowing;

  const isFlattened = Object.entries(selectedFilters).some(([filter, selected]) => {
    return filter !== 'parent' && selected.length > 0;
  });

  const api = useMemo(() => {
    return {
      toggleFilterValue,
      selectedFilters,
      variables,
      resetFilters,
      hasSelectedFilters,
      isCriticalPathShowing,
      isFlattened,
      criticalPath,
      setCriticalPath,
      clearCriticalPath,
      isObjOnCriticalPath,
      selectedSavedFilter,
      setSelectedSavedFilter,
    };
  }, [
    toggleFilterValue,
    clearCriticalPath,
    selectedFilters,
    variables,
    resetFilters,
    hasSelectedFilters,
    isCriticalPathShowing,
    isFlattened,
    criticalPath,
    isObjOnCriticalPath,
    selectedSavedFilter,
    setSelectedSavedFilter,
  ]);

  return (
    <FilterContext.Provider
      value={api}
    >
      {children}
    </FilterContext.Provider>
  );
};
