import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type AddNewCollaboratorsMutationVariables = Types.Exact<{
  shares: Array<Types.SharesInput> | Types.SharesInput;
  teamShares: Types.InputMaybe<Array<Types.TeamShareInput> | Types.TeamShareInput>;
}>;


export type AddNewCollaboratorsMutation = { __typename?: 'Mutation', addShares: Array<{ __typename?: 'Share', id: string | null, externalType: string | null, externalId: string | null, role: Types.ShareRole | null, userId: string | null, user: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null } | null } | null>, addTeamShares: { __typename?: 'TeamShareResults', teamShares: Array<{ __typename?: 'TeamShare', id: string, teamId: string, modularObjectId: string, defaultRole: Types.ShareRole }>, shares: Array<{ __typename?: 'Share', id: string | null, externalType: string | null, externalId: string | null, role: Types.ShareRole | null, userId: string | null }> } };


export const AddNewCollaboratorsDocument = gql`
    mutation AddNewCollaborators($shares: [SharesInput!]!, $teamShares: [TeamShareInput!]) {
  addShares(input: $shares) {
    id
    externalType
    externalId
    role
    userId
    user {
      id
      email
      firstName
      lastName
      profileImage
    }
  }
  addTeamShares(input: $teamShares) {
    teamShares {
      id
      teamId
      modularObjectId
      defaultRole
    }
    shares {
      id
      externalType
      externalId
      role
      userId
    }
  }
}
    `;
export type AddNewCollaboratorsMutationFn = Apollo.MutationFunction<AddNewCollaboratorsMutation, AddNewCollaboratorsMutationVariables>;

/**
 * __useAddNewCollaboratorsMutation__
 *
 * To run a mutation, you first call `useAddNewCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewCollaboratorsMutation, { data, loading, error }] = useAddNewCollaboratorsMutation({
 *   variables: {
 *      shares: // value for 'shares'
 *      teamShares: // value for 'teamShares'
 *   },
 * });
 */
export function useAddNewCollaboratorsMutation(baseOptions?: Apollo.MutationHookOptions<AddNewCollaboratorsMutation, AddNewCollaboratorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewCollaboratorsMutation, AddNewCollaboratorsMutationVariables>(AddNewCollaboratorsDocument, options);
      }
export type AddNewCollaboratorsMutationHookResult = ReturnType<typeof useAddNewCollaboratorsMutation>;
export type AddNewCollaboratorsMutationResult = Apollo.MutationResult<AddNewCollaboratorsMutation>;
export type AddNewCollaboratorsMutationOptions = Apollo.BaseMutationOptions<AddNewCollaboratorsMutation, AddNewCollaboratorsMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddNewCollaboratorsMutation((req, res, ctx) => {
 *   const { shares, teamShares } = req.variables;
 *   return res(
 *     ctx.data({ addShares, addTeamShares })
 *   )
 * })
 */
export const mockAddNewCollaboratorsMutation = (resolver: Parameters<typeof graphql.mutation<AddNewCollaboratorsMutation, AddNewCollaboratorsMutationVariables>>[1]) =>
  graphql.mutation<AddNewCollaboratorsMutation, AddNewCollaboratorsMutationVariables>(
    'AddNewCollaborators',
    resolver
  )
