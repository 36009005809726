import type LeaderLine from 'leader-line-new';
import { addArc } from './addArc';

export function updateLeaderLinePosition (objectId: string, leaderLineInstance: LeaderLine) {
  // Update the leader line position
  leaderLineInstance.position();

  // Add arc to the updated leader line path
  // Get the leader line svg path
  const leaderLine = document.getElementById(`${objectId}-tree-line`);
  const path = leaderLine?.querySelector('path');
  if (!path) return;
  // Don't add arc if it already exists
  if (path.getAttribute('d').includes('A')) return;
  path.setAttribute('d', addArc(path.getAttribute('d'), 8));
}
