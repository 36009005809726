export default function DocumentRowHeader (): JSX.Element {
  return (
    <div className='flex py-3 w-full font-bold'>
      {/* 81px is the width of the parent padding + whatever it takes to get to 105px width, which is what is in the design */}
      <div className='basis-[81px]' />
      <div className='flex-[3_3_0%]'>
        Name
      </div>
      <div className='flex-[3_3_0%] capitalize'>
        Parent
      </div>
      <div className='flex-1 capitalize'>
        Added On
      </div>
      <div className='w-4' />
    </div>
  );
}
