import { NoOverlapGapBehaviorIcon } from '@/components/Icons/GapBehaviorIcons';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { useAppSelector } from '@/state/hooks';
import { useGetSubscriptionQuery } from '@/state/queries/subscription.api';
import { selectRootState } from '@/state/selectors';
import { getParentObject } from '@/util/lookup.functions';
import {
  faCircleArrowRight,
  faClock,
  faGear,
  faListTree,
  faMessageLines,
  faPaperclipVertical,
  faSparkle,
} from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { useObjectContext } from '../../../Card/Object.context';
import useShouldAnimate from '../../../hooks/useShouldAnimate';
import HeaderTab from './HeaderTab';

interface HeaderLowerProps {
  isCompressedHeader?: boolean;
}

export default function HeaderLower ({ isCompressedHeader }: Readonly<HeaderLowerProps>): JSX.Element {
  const state = useAppSelector(selectRootState);
  const { templates } = state;
  const { data: currentSubscription } = useGetSubscriptionQuery(false);
  const isDisabled = currentSubscription?.featureLimits?.isObjectUpdateColumnDisabled;
  const { activeTemplate, activeModularObject } = useObjectContext();
  const loggedInUser = useLoggedInUser();

  const isObjectEditor = (activeModularObject?.shares)?.some((share) => {
    return share?.userId === loggedInUser?.id && share?.role?.toUpperCase() === 'EDITOR';
  });
  const isObjectOwner = activeModularObject?.ownerId === loggedInUser?.id;
  const shouldShowCommentSection = isObjectEditor || isObjectOwner;
  const isTask = activeTemplate?.type === 'task';

  const [parentObject] = getParentObject('modular_object', activeModularObject.id, state);
  const parentTemplate = templates[parentObject?.templateId];

  const isSubtask = parentTemplate?.type === TemplateType.Task;

  const shouldShowTasks = !isSubtask;

  const shouldAnimate = useShouldAnimate();

  return (
    <div className='relative -z-10'>
      <div
        className={cx('gap-[16px] flex overflow-x-scroll no-scrollbar transition-all duration-500 transform-gpu', {
          'animate-object-change': shouldAnimate,
          'pb-[6px] px-[24px]': isCompressedHeader,
          'p-[24px]': !isCompressedHeader,
        })}
      >
        <HeaderTab icon={faSparkle} name='Details' />
        <HeaderTab icon={faGear} name='Attributes' />
        <HeaderTab icon={faPaperclipVertical} name='Files & Links' />
        {!isTask && <HeaderTab icon={faListTree} name='Nesting' />}
        {shouldShowTasks && <HeaderTab icon={faCircleArrowRight} name={isTask ? 'Nested Drivers' : 'Drivers'} />}
        <HeaderTab
          name='Dependencies'
          RenderIcon={() => <NoOverlapGapBehaviorIcon height='12px' width='16px' />}
        />

        {shouldShowCommentSection && <HeaderTab icon={faMessageLines} name='Comments' />}
        <HeaderTab icon={faClock} name='Updates' disabled={isDisabled} proFeature />
      </div>
      {/* Overflow Gradient Fades */}
      <>
        <div className='absolute top-0 left-0 h-full bg-gradient-to-l from-transparent to-white w-[24px]' />
        <div className='absolute top-0 right-0 h-full bg-gradient-to-r from-transparent to-white w-[24px]' />
      </>
    </div>
  );
}
