import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { ObjectListRowPropsFragmentDoc } from '../AddNewObjectModal/ObjectListRow/ObjectListRowProps.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetAddToParentModalResultsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetAddToParentModalResultsQuery = { __typename?: 'Query', getPossibleParentsForModularObject: Array<{ __typename?: 'ModularObject', id: string, name: string | null, deactivated: boolean | null, ownerId: string | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null } | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, createdAt: any | null, externalId: string | null, externalType: string | null, modifiedAt: any | null, role: Types.ShareRole | null, userId: string | null }> | null }> | null };


export const GetAddToParentModalResultsDocument = gql`
    query GetAddToParentModalResults($id: UUID!) {
  getPossibleParentsForModularObject(id: $id) {
    ...ObjectListRowProps
  }
}
    ${ObjectListRowPropsFragmentDoc}`;

/**
 * __useGetAddToParentModalResultsQuery__
 *
 * To run a query within a React component, call `useGetAddToParentModalResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddToParentModalResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddToParentModalResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddToParentModalResultsQuery(baseOptions: Apollo.QueryHookOptions<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>(GetAddToParentModalResultsDocument, options);
      }
export function useGetAddToParentModalResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>(GetAddToParentModalResultsDocument, options);
        }
export function useGetAddToParentModalResultsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>(GetAddToParentModalResultsDocument, options);
        }
export type GetAddToParentModalResultsQueryHookResult = ReturnType<typeof useGetAddToParentModalResultsQuery>;
export type GetAddToParentModalResultsLazyQueryHookResult = ReturnType<typeof useGetAddToParentModalResultsLazyQuery>;
export type GetAddToParentModalResultsSuspenseQueryHookResult = ReturnType<typeof useGetAddToParentModalResultsSuspenseQuery>;
export type GetAddToParentModalResultsQueryResult = Apollo.QueryResult<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAddToParentModalResultsQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getPossibleParentsForModularObject })
 *   )
 * })
 */
export const mockGetAddToParentModalResultsQuery = (resolver: Parameters<typeof graphql.query<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>>[1]) =>
  graphql.query<GetAddToParentModalResultsQuery, GetAddToParentModalResultsQueryVariables>(
    'GetAddToParentModalResults',
    resolver
  )
