import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type TeamsForDisplayQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeamsForDisplayQuery = { __typename?: 'Query', teams: { __typename?: 'TeamConnection', edges: Array<{ __typename?: 'Team', id: string, name: string } | null> | null } };


export const TeamsForDisplayDocument = gql`
    query TeamsForDisplay {
  teams {
    edges {
      id
      name
    }
  }
}
    `;

/**
 * __useTeamsForDisplayQuery__
 *
 * To run a query within a React component, call `useTeamsForDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsForDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsForDisplayQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsForDisplayQuery(baseOptions?: Apollo.QueryHookOptions<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>(TeamsForDisplayDocument, options);
      }
export function useTeamsForDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>(TeamsForDisplayDocument, options);
        }
export function useTeamsForDisplaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>(TeamsForDisplayDocument, options);
        }
export type TeamsForDisplayQueryHookResult = ReturnType<typeof useTeamsForDisplayQuery>;
export type TeamsForDisplayLazyQueryHookResult = ReturnType<typeof useTeamsForDisplayLazyQuery>;
export type TeamsForDisplaySuspenseQueryHookResult = ReturnType<typeof useTeamsForDisplaySuspenseQuery>;
export type TeamsForDisplayQueryResult = Apollo.QueryResult<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockTeamsForDisplayQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ teams })
 *   )
 * })
 */
export const mockTeamsForDisplayQuery = (resolver: Parameters<typeof graphql.query<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>>[1]) =>
  graphql.query<TeamsForDisplayQuery, TeamsForDisplayQueryVariables>(
    'TeamsForDisplay',
    resolver
  )
