import dayjs from 'dayjs';

export default function sortByDates (a, b): number {
  // In the UI, tasks are displayed before objects, so if a is an object and b is a task, a should come first
  // Also not checking for === 'hardware' as there is a 'program' type which is treated the same as hardware
  if (a?.template?.type !== 'task' && b?.template?.type === 'task') {
    return 1;
  }
  if (a?.template?.type === 'task' && b?.template?.type !== 'task') {
    return -1;
  }

  const aStartDate = a.startDate ? dayjs(a.startDate) : null;
  const bStartDate = b.startDate ? dayjs(b.startDate) : null;

  const aTargetDate = a.targetDate ? dayjs(a.targetDate) : null;
  const bTargetDate = b.targetDate ? dayjs(b.targetDate) : null;

  const aDates = [aStartDate, aTargetDate, a.calculatedStartDate, a.calculatedTargetDate].filter(Boolean);
  const bDates = [bStartDate, bTargetDate, b.calculatedStartDate, b.calculatedTargetDate].filter(Boolean);

  if (!aDates.length && bDates.length) return -1;
  if (!bDates.length && aDates.length) return 1;
  if (!aDates.length && !bDates.length) return 0;

  // Instance where both have the exact same dates, we should sort alphabetically by name
  if (dayjs.min(aDates).isSame(dayjs.min(bDates))) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
  }

  if (dayjs.min(aDates).isBefore(dayjs.min(bDates))) return -1;
  if (dayjs.min(aDates).isAfter(dayjs.min(bDates))) return 1;

  return 0;
}
