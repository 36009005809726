import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function getHeaderMonthValues (startDate, endDate, scaleUnit): { headers: any[] } {
  const headers = [];

  if (!startDate) {
    startDate = dayjs();
  }

  let cellCount = dayjs(endDate).diff(dayjs(startDate), scaleUnit);

  if (scaleUnit === 'quarter') {
    cellCount = dayjs(endDate).startOf('quarter').diff(dayjs(startDate).startOf('quarter'), 'quarter');
  }

  Array(cellCount)
    .fill('')
    .forEach((_, index) => {
      const day = scaleUnit === 'quarter'
        ? startDate.startOf('quarter').add(index, 'quarter')
        : startDate.add(index, scaleUnit);

      const config = {
        day,
        year: day.get('year'),
        month: day.get('month'),
        date: day.date(),
        zoom: scaleUnit ?? 'year',
        today: dayjs().isSame(day, scaleUnit),
      };

      headers.push(config);
    });

  return { headers };
}
