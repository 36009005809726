import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import { ScheduleModularObjectFragmentDoc } from '../fragments/modularObject.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DuplicateModularObjectTreeMutationVariables = Types.Exact<{
  rootIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type DuplicateModularObjectTreeMutation = { __typename?: 'Mutation', duplicateModularObjectTree: Array<{ __typename?: 'ModularObject', id: string, name: string | null, startDate: any | null, targetDate: any | null, lValue: number | null, assigneeId: string | null, status: Types.TaskStatus | null, statusModuleId: string | null, templateId: string | null, ownerId: string | null, visibility: string | null, deactivated: boolean | null, permission: Types.Permission | null, assignee: { __typename?: 'User', id: string, email: string | null, organizationId: string | null } | null, duration: { __typename?: 'ModularObjectDuration', startDate: any | null, targetDate: any | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null, subType: Types.TemplateSubTypes | null, type: Types.TemplateTypes | null } | null, owner: { __typename?: 'User', id: string, email: string | null, organizationId: string | null } | null, dependencies: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, activeAt: any | null, gapDays: number | null, gapMonths: number | null, gapYears: number | null }> | null, children: Array<{ __typename?: 'ModularObject', id: string, name: string | null, permission: Types.Permission | null, startDate: any | null, targetDate: any | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, name: string | null } | null }> | null, parent: { __typename?: 'ModularObject', id: string, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, name: string | null } | null, duration: { __typename?: 'ModularObjectDuration', startDate: any | null, targetDate: any | null } | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, role: Types.ShareRole | null, userId: string | null, user: { __typename?: 'User', id: string, organizationId: string | null } | null }> | null }> };


export const DuplicateModularObjectTreeDocument = gql`
    mutation DuplicateModularObjectTree($rootIds: [UUID!]!) {
  duplicateModularObjectTree(rootIds: $rootIds) {
    ...ScheduleModularObject
  }
}
    ${ScheduleModularObjectFragmentDoc}`;
export type DuplicateModularObjectTreeMutationFn = Apollo.MutationFunction<DuplicateModularObjectTreeMutation, DuplicateModularObjectTreeMutationVariables>;

/**
 * __useDuplicateModularObjectTreeMutation__
 *
 * To run a mutation, you first call `useDuplicateModularObjectTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateModularObjectTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateModularObjectTreeMutation, { data, loading, error }] = useDuplicateModularObjectTreeMutation({
 *   variables: {
 *      rootIds: // value for 'rootIds'
 *   },
 * });
 */
export function useDuplicateModularObjectTreeMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateModularObjectTreeMutation, DuplicateModularObjectTreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateModularObjectTreeMutation, DuplicateModularObjectTreeMutationVariables>(DuplicateModularObjectTreeDocument, options);
      }
export type DuplicateModularObjectTreeMutationHookResult = ReturnType<typeof useDuplicateModularObjectTreeMutation>;
export type DuplicateModularObjectTreeMutationResult = Apollo.MutationResult<DuplicateModularObjectTreeMutation>;
export type DuplicateModularObjectTreeMutationOptions = Apollo.BaseMutationOptions<DuplicateModularObjectTreeMutation, DuplicateModularObjectTreeMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDuplicateModularObjectTreeMutation((req, res, ctx) => {
 *   const { rootIds } = req.variables;
 *   return res(
 *     ctx.data({ duplicateModularObjectTree })
 *   )
 * })
 */
export const mockDuplicateModularObjectTreeMutation = (resolver: Parameters<typeof graphql.mutation<DuplicateModularObjectTreeMutation, DuplicateModularObjectTreeMutationVariables>>[1]) =>
  graphql.mutation<DuplicateModularObjectTreeMutation, DuplicateModularObjectTreeMutationVariables>(
    'DuplicateModularObjectTree',
    resolver
  )
