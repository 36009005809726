import HookFormLabel from '@/components/modules/HookFormLabel';
import cx from 'classnames';
import { type ReactNode } from 'react';

interface DetailItemProps {
  name?: string;
  label: string;
  children: ReactNode;
  contentClassName?: string;
  labelClassName?: string;
}

export default function DetailItem (
  { label, name, children, contentClassName, labelClassName }: DetailItemProps,
): JSX.Element {
  return (
    <div className='flex items-center w-full min-h-[32px] gap-[8px]' data-testid='detail-item'>
      <HookFormLabel
        label={label}
        name={name}
        className={cx('basis-[40%] max-w-[100px] effra-xs !mb-0', labelClassName)}
      />
      <div className={cx('flex-1', contentClassName)}>
        {children}
      </div>
    </div>
  );
}
