import { GetObjectCardObjectDocument } from '@/components/common/ModularObject/Card/GetObjectCardObject.generated';
import TextInput from '@/components/inputs/TextInput/TextInput';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import Modal from '@/designSystemComponents/Modal/Modal';
import { useModal } from '@/hooks/useModal';
import isUrl from 'is-valid-http-url';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useCreateLinksMutation } from './createLinks.generated';

export interface AddLinkModalProps {
  modularObjectId: string;
  onSubmitStart?: () => void;
  onSubmitEnd?: () => void;
}

interface Inputs {
  url: string;
  title: string;
}

export default function AddLinkModal ({ modularObjectId, onSubmitStart, onSubmitEnd }: AddLinkModalProps): JSX.Element {
  const { handleSubmit, register, formState: { isValid } } = useForm<Inputs>();
  const { closeModal } = useModal();

  const [createLinks, { loading: isLoading }] = useCreateLinksMutation();

  const validateUrl = (url: string) => {
    if (!isUrl(url)) {
      return 'Please enter a valid URL';
    }
    return true;
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (onSubmitStart) {
      onSubmitStart();
    }

    await createLinks({
      variables: {
        input: [{
          modularObjectId,
          url: data.url,
          title: data.title,
        }],
      },
      refetchQueries: [GetObjectCardObjectDocument],
      onCompleted: () => {
        addToastSuccess('Link added successfully');
      },
      onError: (error) => {
        addToastError(error.message);
      },
    });

    if (onSubmitEnd) {
      onSubmitEnd();
    }

    closeModal();
  };

  return (
    <Modal title='Add Link' testId='add-link-modal'>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-[16px]'>
        <TextInput
          {...register('url', { required: true, validate: validateUrl })}
          placeholder='https://...'
        />
        <TextInput
          {...register('title', { required: false })}
          placeholder='Title (optional)'
        />
        <Button disabled={!isValid || isLoading} type='submit' figmaProps={{ style: 'fill', size: 'large' }}>
          Add Link
        </Button>
      </form>
    </Modal>
  );
}
