import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DeleteCollaboratorMutationVariables = Types.Exact<{
  input: Array<Types.SharesInput> | Types.SharesInput;
}>;


export type DeleteCollaboratorMutation = { __typename?: 'Mutation', deleteShares: Array<{ __typename?: 'Share', id: string | null, externalType: string | null, externalId: string | null, role: Types.ShareRole | null, userId: string | null, user: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null } | null } | null> };


export const DeleteCollaboratorDocument = gql`
    mutation DeleteCollaborator($input: [SharesInput!]!) {
  deleteShares(input: $input) {
    id
    externalType
    externalId
    role
    userId
    user {
      id
      email
      firstName
      lastName
      profileImage
    }
  }
}
    `;
export type DeleteCollaboratorMutationFn = Apollo.MutationFunction<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>;

/**
 * __useDeleteCollaboratorMutation__
 *
 * To run a mutation, you first call `useDeleteCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollaboratorMutation, { data, loading, error }] = useDeleteCollaboratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCollaboratorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>(DeleteCollaboratorDocument, options);
      }
export type DeleteCollaboratorMutationHookResult = ReturnType<typeof useDeleteCollaboratorMutation>;
export type DeleteCollaboratorMutationResult = Apollo.MutationResult<DeleteCollaboratorMutation>;
export type DeleteCollaboratorMutationOptions = Apollo.BaseMutationOptions<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteCollaboratorMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ deleteShares })
 *   )
 * })
 */
export const mockDeleteCollaboratorMutation = (resolver: Parameters<typeof graphql.mutation<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>>[1]) =>
  graphql.mutation<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>(
    'DeleteCollaborator',
    resolver
  )
