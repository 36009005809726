export function updateLeaderLineWrapper (wrapperElement: HTMLElement): void {
  // Update wrapper
  if (!wrapperElement) return;
  // Get the current transform for the wrapper
  const currentTransform = window.getComputedStyle(wrapperElement).transform;
  const transformMatrix = new WebKitCSSMatrix(currentTransform);
  const currentX = transformMatrix.m41;
  const currentY = transformMatrix.m42;

  // Get the updated wrapper position
  const rectWrapper = wrapperElement.getBoundingClientRect();
  const newX = currentX - rectWrapper.left;
  const newY = currentY - rectWrapper.top;

  // Set the new transform for the wrapper
  const newTransform = `translate(${newX}px, ${newY}px)`;
  wrapperElement.style.transform = newTransform;
}
