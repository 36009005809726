import UpdatesColumn from '@/components/cards/UpdatesColumn/UpdatesColumn';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { memo, useMemo } from 'react';
import CustomFields from '../../CustomFields/CustomFields';
import NestingSection from '../../Nesting/NestingSection';
import TaskSection from '../../Tasks/TaskSection/TaskSection';
import AttributesSection from '../Card v2/Attributes';
import CardSection from '../Card v2/CardSection';
import { CommentSection } from '../Card v2/CommentSection/CommentSection';
import DetailsSection from '../Card v2/DetailsSection/DetailsSection';
import DependencySection from '../DependencySection/DependencySection';
import { FileUploadSection } from '../FileUploadSection/FileUploadSection';

// Content displayed beneath the header of the ObjectCard.
const ObjectCardContent = memo(function ObjectCardContent (): JSX.Element {
  const loggedInUser = useLoggedInUser();
  const { objectCardData, activeModularObjectId } = useObjectCardContext();

  const isTask = objectCardData?.template?.type === 'task';

  const isObjectEditor = (objectCardData?.shares ?? [])?.some((share) => {
    return share?.userId === loggedInUser?.id && share?.role?.toUpperCase() === 'EDITOR';
  });

  const isObjectOwner = objectCardData?.ownerId === loggedInUser?.id;

  const shouldShowCommentSection = useMemo(() => isObjectEditor || isObjectOwner, [isObjectEditor, isObjectOwner]);

  const isSubtask = objectCardData?.parent?.template?.type === TemplateType.Task;
  const shouldShowSubtasks = !isSubtask;

  return (
    <div className='flex flex-col bg-neutral-100'>
      <CardSection title='Details' className='pb-8'>
        <DetailsSection />
      </CardSection>
      <CardSection title='Attributes' className='pb-8'>
        <AttributesSection />
        <CustomFields />
      </CardSection>
      <CardSection title='Files & Links' className='pb-[16px]'>
        <FileUploadSection />
      </CardSection>
      {!isTask && (
        <CardSection title='Nesting' className='pb-8 shadow-none'>
          <NestingSection />
        </CardSection>
      )}
      {shouldShowSubtasks && (
        <CardSection title={isTask ? 'Nested Drivers' : 'Drivers'} className='px-1 pb-8 w-full'>
          <TaskSection hardware={objectCardData} hardwareType='modular_object' />
        </CardSection>
      )}
      <CardSection title='Dependencies' className='pb-8'>
        <DependencySection
          objectId={activeModularObjectId}
          templateId={objectCardData?.templateId}
          templateName={objectCardData?.template?.name}
        />
      </CardSection>
      {shouldShowCommentSection && (
        <CardSection title='Comments' className='pb-8'>
          <CommentSection />
        </CardSection>
      )}

      <CardSection title='Updates' className='pb-8'>
        <UpdatesColumn objectID={activeModularObjectId} objectType='modular_object' />
      </CardSection>
    </div>
  );
});

export default ObjectCardContent;
