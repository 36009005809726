import type { ScheduleModularObjectFragment } from '@/graphql/fragments/modularObject.generated';
import { useLeaderLines } from '@/hooks/useLeaderLines';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { type ScheduleViewQueryParams, useQueryParams } from '@/hooks/useQueryParams';
import type { ModularObject } from '@/models/modularObject.model';
import sortByDates from '@/util/sortByDates.functions';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import type LeaderLine from 'leader-line-new';
import { cloneDeep, unionBy } from 'lodash';
import {
  createContext,
  type MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { type AdditionalObjectProps, ScheduleView, type TimelineZoomLevel } from './constants';
import { useFilterContext } from './FilterContext';
import { useGetAllTasksQuery } from './queries/getAllTasks.generated';
import { useGetTopLevelModularObjectsQuery } from './queries/getTopLevelModularObjects.generated';
import getHeaderMonthValues from './Timeline/Gantt/lib/getHeaderMonthValues';
import { updateLeaderLinePosition } from './Timeline/Gantt/ScheduleGanttTree/updateLeaderLinePosition';
import { updateLeaderLineWrapper } from './Timeline/Gantt/ScheduleGanttTree/updateLeaderLineWrapper';
import useTimelineConfig, { type TimelineConfig } from './Timeline/hooks/useTimelineConfig';

dayjs.extend(minMax);

interface FlattenedListItem {
  isTopLevel: boolean;
  object: ScheduleModularObjectFragment;
}

interface DateHeader {
  day: Dayjs;
  date: number;
  today: boolean;
  month: string;
  year: number;
  isLaunchDate: boolean;
}
interface ScheduleContextType {
  scheduleView: ScheduleView;
  setScheduleView: (scheduleView: ScheduleView) => void;
  timelineConfig: TimelineConfig;
  filterText: string;
  setFilterText: (filterText: string) => void;
  isLoadingTopLevelObjects: boolean;

  isLoadingAllTasks: boolean;
  toggleObject: (objectId: string) => void;
  toggleObjectTasks: (objectId: string) => void;
  toggleObjectRequirements: (objectId: string) => void;
  toggleObjectMilestones: (objectId: string) => void;
  isObjectExpanded: (objectId: string) => boolean;
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
  saveViewSetting: (isExpandedView: boolean) => void;
  isObjectTasksExpanded: (objectId: string) => boolean;
  isObjectMilestonesExpanded: (objectId: string) => boolean;
  isObjectRequirementsExpanded: (objectId: string) => boolean;
  schedule: Array<ScheduleModularObjectFragment & AdditionalObjectProps>;
  visibleChildren: Set<ScheduleModularObjectFragment>;

  collapseAll: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteredTasks: any[];

  dateHeaders: DateHeader[];

  objectRefs: Record<string, MutableRefObject<HTMLDivElement>>;
  registerRef: (
    objectId: string,
    ref: MutableRefObject<HTMLDivElement>,
  ) => void;
  getObjectRef: (objectId: string) => MutableRefObject<HTMLDivElement>;

  registerLeaderLine: (
    objectId: string,
    ref: LeaderLine,
    parentRef: MutableRefObject<HTMLElement>,
    objectRef: MutableRefObject<HTMLElement>,
    wrapperRef: MutableRefObject<HTMLElement>,
  ) => void;

  registerObjectChildrenToggleRef: (
    objectId: string,
    ref: MutableRefObject<HTMLDivElement>,
  ) => void;
  getObjectChildrenToggleRef: (
    objectId: string,
  ) => MutableRefObject<HTMLDivElement>;
  updateGanttTreeVisualizations: () => void;
  registerObjectChildren: (children: ModularObject[], objectId: string) => void;
  unregisterObjectChildren: (parentId: string, objectId: string) => void;
  getChildren: (objectId: string) => [];
}

interface ScheduleContextProviderProps {
  values?: Partial<ScheduleContextType>;
  children: (args: ScheduleContextType) => JSX.Element;
}

const ScheduleContext = createContext<ScheduleContextType>(null);

export function useScheduleContext (): Readonly<ScheduleContextType> {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error(
      'ScheduleContext must be used within a ScheduleContextProvider',
    );
  }

  return context;
}

interface LeaderLineRef {
  leaderLineRef: LeaderLine;
  parentRef: MutableRefObject<HTMLElement>;
  objectRef: MutableRefObject<HTMLElement>;
  wrapperRef: MutableRefObject<HTMLElement>;
}

// The cleanup of leader lines built into the package does not work because we are moving the leader lines outside the <body /> element of the document
// This means that leader lines tries to find the line to remove in the <body /> element, but it is not there
// This array stores all the leader lines we are using for critical path so we re-append them to the <body /> element when the component unmounts
// This ensures that the leader line cleanup works as expected
let criticalPathDOMLines = [];
const SIDE_NAV_WIDTH_IN_PX = 216;
const TOP_HEADER_HEIGHT_IN_PX = 136;

export function ScheduleContextProvider (
  { children, values }: Readonly<ScheduleContextProviderProps>,
): JSX.Element {
  const [viewSetting, saveViewSetting] = useLocalStorage('expanded', false);
  const [isExpandedView, setIsExpandedView] = useState<boolean>(viewSetting);
  const [filterText, setFilterText] = useState<string>('');
  const [expandedObjects, setExpandedObjects] = useState<Set<string>>(
    new Set(),
  );
  const [expandedTasks, setExpandedTasks] = useState<Set<string>>(new Set());
  const [expandedRequirements, setExpandedRequirements] = useState<Set<string>>(new Set());
  const [expandedMilestones, setExpandedMilestones] = useState<Set<string>>(new Set());

  const [objectRefs, setObjectRefs] = useState<
    Record<string, MutableRefObject<HTMLDivElement>>
  >({});
  const [objectChildrenToggleRefs, setObjectChildrenToggleRefs] = useState<
    Record<string, MutableRefObject<HTMLDivElement>>
  >({});
  const [leaderLines, setLeaderLines] = useState<Record<string, LeaderLineRef>>(
    {},
  );
  const [registeredObjectTree, setRegisteredObjectTree] = useState({});

  const { updateUrlParams, queryParams } = useQueryParams<
    ScheduleViewQueryParams
  >();
  const scheduleView = queryParams.scheduleView
    ? queryParams.scheduleView
    : ScheduleView.Gantt;

  const setScheduleView = useCallback(
    (scheduleView: ScheduleView): void => {
      const { scheduleView: prevScheduleView, ...rest } = queryParams;
      updateUrlParams({ ...rest, scheduleView });
    },
    [queryParams, updateUrlParams],
  );

  const timelineConfig = useTimelineConfig(
    queryParams?.zoom as TimelineZoomLevel,
  );

  const {
    removeAllLeaderLines: removeAllCriticalPathLeaderLines,
    addLeaderLine: addCriticalPathLeaderLine,
    reDrawLeaderLines: reDrawCriticalPathLeaderLines,
    leaderLineLib,
  } = useLeaderLines();

  const {
    selectedFilters,
    variables,
    isCriticalPathShowing,
    criticalPath,
    isFlattened,
  } = useFilterContext();
  const { data: allTasksData, loading: isLoadingAllTasks, previousData: allTasksPreviousData } = useGetAllTasksQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const tasks = useMemo(() => isLoadingAllTasks ? allTasksPreviousData?.getAllTasks : allTasksData?.getAllTasks ?? [], [
    allTasksData?.getAllTasks,
    allTasksPreviousData?.getAllTasks,
    isLoadingAllTasks,
  ]);

  const {
    data: _topLevelData,
    loading: isLoadingTopLevelObjects,
    previousData: topLevelPreviousData,
  } = useGetTopLevelModularObjectsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const topLevelData = isLoadingTopLevelObjects ? topLevelPreviousData : _topLevelData;

  const modularObjects = useMemo(() => {
    if (!topLevelData?.getTopLevelModularObjects) return [];

    const objects = isFlattened
      ? topLevelData?.getTopLevelModularObjects
      : topLevelData?.getTopLevelModularObjects?.filter((obj) => obj?.template?.type?.toLowerCase() !== 'task');

    const modularObjects = isCriticalPathShowing ? criticalPath : objects;

    return modularObjects.map((obj) => {
      const calculatedStartDate = obj.duration.startDate
        ? dayjs(obj.duration.startDate)
        : null;
      const calculatedTargetDate = obj.duration.targetDate
        ? dayjs(obj.duration.targetDate)
        : null;
      return {
        ...obj,
        calculatedStartDate,
        calculatedTargetDate,
      };
    });
  }, [
    criticalPath,
    isFlattened,
    isCriticalPathShowing,
    topLevelData?.getTopLevelModularObjects,
  ]);

  const sortedModularObjects = modularObjects?.sort(sortByDates);

  const { startDate, endDate } = timelineConfig;

  const registerLeaderLine = useCallback(
    (
      objectId: string,
      ref: LeaderLine,
      parentRef,
      objectRef,
      wrapperRef,
    ): void => {
      setLeaderLines((prev) => ({
        ...prev,
        [objectId]: {
          leaderLineRef: ref,
          parentRef,
          objectRef,
          wrapperRef,
        },
      }));
    },
    [],
  );

  const updateLeaderLines = useCallback((): void => {
    Object.entries(leaderLines).forEach(
      ([objectId, { leaderLineRef, parentRef, objectRef }]) => {
        if (!leaderLineRef || !parentRef?.current || !objectRef?.current) {
          return;
        }
        updateLeaderLinePosition(objectId, leaderLineRef);
      },
    );
  }, [leaderLines]);

  const updateLeaderLineWrappers = useCallback((): void => {
    Object.values(leaderLines).forEach(({ wrapperRef }) => {
      // Update wrapper
      const wrapperElement = wrapperRef?.current;
      updateLeaderLineWrapper(wrapperElement);
    });
  }, [leaderLines]);

  const updateGanttTreeVisualizations = useCallback(() => {
    updateLeaderLines();
    updateLeaderLineWrappers();
  }, [updateLeaderLineWrappers, updateLeaderLines]);

  useEffect(() => {
    updateGanttTreeVisualizations();
  }, [expandedObjects, expandedTasks, expandedMilestones, expandedRequirements, isExpandedView, leaderLines]);

  useLayoutEffect(() => {
    if (criticalPath.length > 0 && scheduleView === ScheduleView.Gantt) {
      criticalPath.forEach((modularObject, index) => {
        const isOnLastObject = index + 1 >= criticalPath.length;
        if (isOnLastObject) return;

        const currentObject = objectRefs[modularObject.id]?.current;
        const nextObjectId = criticalPath[index + 1].id;
        const nextObject = objectRefs[nextObjectId]?.current;
        if (!currentObject || !nextObject) return;

        const anchorA = leaderLineLib.pointAnchor(currentObject, {
          x: 'auto',
          y: '100%',
        });
        const anchorB = leaderLineLib.pointAnchor(nextObject, {
          x: '0%',
          y: '50%',
        });

        addCriticalPathLeaderLine(anchorA, anchorB, {
          startSocket: 'bottom',
          endSocket: 'left',
          color: '#EF2F95',
          size: 2,
          dash: { animation: true },
        });

        const criticalPathLineContainer: HTMLElement = document.querySelector(
          '#critical-path-wrapper',
        );
        const xScrollContainer = document.querySelector(
          '[data-scrollable-chart=\'data-scrollable-chart\']',
        );

        criticalPathLineContainer.style.transform = `translate(${
          xScrollContainer.scrollLeft - SIDE_NAV_WIDTH_IN_PX
        }px, -${TOP_HEADER_HEIGHT_IN_PX}px)`;

        const line = document.querySelector<SVGElement>(
          'body>.leader-line:last-of-type',
        );
        criticalPathDOMLines.push(line);
        criticalPathLineContainer.appendChild(line);
      });

      return () => {
        const body = document.querySelector('body');
        criticalPathDOMLines.forEach((line) => body.appendChild(line));
        criticalPathDOMLines = [];
        removeAllCriticalPathLeaderLines();
      };
    }
  }, [
    isLoadingTopLevelObjects,
    criticalPath,
    reDrawCriticalPathLeaderLines,
    objectRefs,
    leaderLineLib,
    addCriticalPathLeaderLine,
    removeAllCriticalPathLeaderLines,
    scheduleView,
  ]);

  const filteredTasks = useMemo(
    () =>
      tasks?.filter((task) =>
        task?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
        Object.values(task?.children || {}).filter((i) => i?.name?.toLowerCase().includes(filterText)).length >
          0 ||
        !filterText?.toLowerCase()
      ),
    [filterText, tasks],
  );

  const expandObject = useCallback(
    async (objectId: string): Promise<void> => {
      const newObjects = new Set([...expandedObjects]);
      setExpandedObjects(newObjects.add(objectId));
    },
    [expandedObjects],
  );

  const collapseObject = useCallback((objectId: string): void => {
    const newObjects = new Set([...expandedObjects]);
    newObjects.delete(objectId);
    setExpandedObjects(newObjects);
  }, [expandedObjects]);

  const collapseAll = useCallback(() => {
    setExpandedObjects(new Set());
  }, [setExpandedObjects]);

  const toggleObject = useCallback(
    async (objectId: string): Promise<void> => {
      // If object is expanded, collapse it
      if (expandedObjects.has(objectId)) {
        collapseObject(objectId);
      } else {
        // If object is not visible, expand it
        await expandObject(objectId);
      }
    },
    [collapseObject, expandObject, expandedObjects],
  );

  const expandObjectMilestones = useCallback(async (objectId: string): Promise<void> => {
    const newMilestones = new Set([...expandedMilestones]);
    setExpandedMilestones(newMilestones.add(objectId));
  }, [expandedMilestones]);

  const collapseObjectMilestones = useCallback((objectId: string): void => {
    const newMilestones = new Set([...expandedMilestones]);
    newMilestones.delete(objectId);
    setExpandedMilestones(newMilestones);
  }, [expandedMilestones]);

  const toggleObjectMilestones = useCallback(async (objectId: string): Promise<void> => {
    // If object is expanded, collapse it
    if (expandedMilestones.has(objectId)) {
      collapseObjectMilestones(objectId);
    } else {
      // If object is not visible, expand it
      await expandObjectMilestones(objectId);
    }
  }, [collapseObjectMilestones, expandObjectMilestones, expandedMilestones]);

  const expandObjectRequirements = useCallback(async (objectId: string): Promise<void> => {
    const newReqs = new Set([...expandedRequirements]);
    setExpandedRequirements(newReqs.add(objectId));
  }, [expandedRequirements]);

  const collapseObjectRequirements = useCallback((objectId: string): void => {
    const newReqs = new Set([...expandedRequirements]);
    newReqs.delete(objectId);
    setExpandedRequirements(newReqs);
  }, [expandedRequirements]);

  const toggleObjectRequirements = useCallback(async (objectId: string): Promise<void> => {
    // If object is expanded, collapse it
    if (expandedRequirements.has(objectId)) {
      collapseObjectRequirements(objectId);
    } else {
      // If object is not visible, expand it
      await expandObjectRequirements(objectId);
    }
  }, [collapseObjectRequirements, expandObjectRequirements, expandedRequirements]);

  const expandObjectTasks = useCallback(async (objectId: string): Promise<void> => {
    const newTasks = new Set([...expandedTasks]);
    setExpandedTasks(newTasks.add(objectId));
  }, [expandedTasks]);

  const collapseObjectTasks = useCallback((objectId: string): void => {
    const newTasks = new Set([...expandedTasks]);
    newTasks.delete(objectId);
    setExpandedTasks(newTasks);
  }, [expandedTasks]);

  const toggleObjectTasks = useCallback(async (objectId: string): Promise<void> => {
    // If object is expanded, collapse it
    if (expandedTasks.has(objectId)) {
      collapseObjectTasks(objectId);
    } else {
      // If object is not visible, expand it
      await expandObjectTasks(objectId);
    }
  }, [collapseObjectTasks, expandObjectTasks, expandedTasks]);

  const isObjectExpanded = useCallback((objectId: string): boolean => {
    return expandedObjects.has(objectId);
  }, [expandedObjects]);

  const isObjectTasksExpanded = useCallback((objectId: string): boolean => {
    return expandedTasks.has(objectId);
  }, [expandedTasks]);

  const isObjectMilestonesExpanded = useCallback((objectId: string): boolean => {
    return expandedMilestones.has(objectId);
  }, [expandedMilestones]);

  const isObjectRequirementsExpanded = useCallback((objectId: string): boolean => {
    return expandedRequirements.has(objectId);
  }, [expandedRequirements]);

  const { headers: dateHeaders } = getHeaderMonthValues(
    startDate,
    endDate,
    timelineConfig.headerColumnValue,
  );

  const registerObjectChildren = useCallback(
    (children: ModularObject[], objectId: string): void => {
      setRegisteredObjectTree((prev) => {
        return {
          ...prev,
          [objectId]: unionBy(prev[objectId] || [], children, 'id'),
        };
      });
    },
    [],
  );

  const unregisterObjectChildren = useCallback((parentId: string, objectId: string): void => {
    setRegisteredObjectTree((prev) => {
      const updatedObjectChildren = prev[parentId].filter((child) => child.id !== objectId);
      return {
        ...prev,
        [parentId]: updatedObjectChildren,
      };
    });
  }, []);

  const getChildren = useCallback((objectId: string) => {
    return registeredObjectTree[objectId] || [];
  }, [registeredObjectTree]);

  const registerRef = useCallback(
    (objectId: string, ref: MutableRefObject<HTMLDivElement>): void => {
      setObjectRefs((prev) => ({ ...prev, [objectId]: ref }));
    },
    [],
  );

  const getObjectRef = useCallback(
    (objectId: string): MutableRefObject<HTMLDivElement> => {
      return objectRefs[objectId];
    },
    [objectRefs],
  );

  const registerObjectChildrenToggleRef = useCallback(
    (objectId: string, ref: MutableRefObject<HTMLDivElement>): void => {
      setObjectChildrenToggleRefs((prev) => ({ ...prev, [objectId]: ref }));
    },
    [],
  );

  const getObjectChildrenToggleRef = useCallback(
    (objectId: string): MutableRefObject<HTMLDivElement> => {
      return objectChildrenToggleRefs[objectId];
    },
    [objectChildrenToggleRefs],
  );

  const getFlattenedTreeSet = (
    schedule: ScheduleModularObjectFragment[],
    sortedSet: Set<FlattenedListItem> = new Set<FlattenedListItem>(),
    depth = 0,
  ) => {
    const clonedSchedule = cloneDeep(schedule);

    clonedSchedule.forEach((object) => {
      // Getting the children because schedule doesn't have any of the nested children data in it.
      const _children = getChildren(object.id);

      sortedSet.add({ isTopLevel: depth === 0, object });
      object.children = _children;

      if (_children?.length) {
        // Add the parent object to the children to be used when attempting to traverse up the object's parents
        // The parent object is mainly used to figure out if the object is currently visible or not
        const { objects, tasks } = _children.reduce((acc, child) => {
          if (child.template.type === 'task') {
            acc.tasks.push({ ...child, parent: { ...object } });
          } else {
            acc.objects.push({ ...child, parent: { ...object } });
          }
          return acc;
        }, {
          objects: [],
          tasks: [],
        });

        const children = [...tasks.sort(sortByDates), ...objects.sort(sortByDates)];

        return getFlattenedTreeSet(children as unknown as ScheduleModularObjectFragment[], sortedSet, depth + 1);
      }
    });

    return sortedSet;
  };

  const flattenedList = getFlattenedTreeSet(sortedModularObjects);

  const isObjectSectionExpanded = useCallback((from: ScheduleModularObjectFragment, objectId: string): boolean => {
    if (!from) {
      return true;
    }

    // TODO: Remove this view check after the object list view also expands tasks by type
    if (scheduleView === ScheduleView.Gantt) {
      // Check "from"' type to check the correct `expanded` state set
      switch (from?.template?.name?.toLowerCase()) {
        case 'tasks':
          return expandedTasks.has(objectId);
        case 'milestones':
          return expandedMilestones.has(objectId);
        case 'requirements':
          return expandedRequirements.has(objectId);
        default:
          return expandedObjects.has(objectId);
      }
    } else if (scheduleView === ScheduleView.List || scheduleView === ScheduleView.Drivers) {
      if (from?.template?.type === 'task') {
        return expandedTasks.has(objectId);
      } else {
        return expandedObjects.has(objectId);
      }
    }
  }, [scheduleView, expandedObjects, expandedTasks, expandedMilestones, expandedRequirements]);

  // Recursive function to determine if an object is visible
  // It does so by recursively checking if the parent of the object is expanded
  // and if any parent of the object is not expanded then the object is not visible
  // NOTE: Do not send depth along with the function call, it's for use inside the function only
  const isObjectVisible = useCallback((
    flattenedListItem: FlattenedListItem,
    from: ScheduleModularObjectFragment = null,
  ): boolean => {
    // If the object is a top level object, then it is always visible
    // If the object doesn't have a parent, then it is a top level object and is always visible
    if (flattenedListItem?.isTopLevel || flattenedListItem?.object?.parent === null) {
      return true;
    }

    // Check if parent is expanded
    const isParentExpanded = isObjectSectionExpanded(from, flattenedListItem?.object?.id);

    // If the parent is not expanded, then we can assume that the object is not visible
    if (!isParentExpanded) {
      return false;
    }

    // If the parent is expanded, then we need to check the parent of the parent to make sure all parents up the chain are expanded
    // before actually confirming that the object is visible
    return isObjectVisible(
      { isTopLevel: false, object: flattenedListItem?.object?.parent as ScheduleModularObjectFragment },
      flattenedListItem?.object,
    );
  }, [isObjectSectionExpanded]);

  const visibleChildren = useMemo<Set<ScheduleModularObjectFragment>>(() => {
    const visibleChildren = new Set<ScheduleModularObjectFragment>();

    // Loop through the flattened tree map and determine which objects are visible
    flattenedList.forEach((child) => {
      const isVisible = isObjectVisible(child);

      if (isVisible) {
        visibleChildren.add(child?.object);
      }
    });

    return visibleChildren;
  }, [flattenedList, isObjectVisible]);

  const value = useMemo(
    () => ({
      scheduleView,
      setScheduleView,
      filterText,
      setFilterText,
      selectedFilters,
      toggleObject,
      toggleObjectTasks,
      toggleObjectRequirements,
      toggleObjectMilestones,
      isObjectExpanded,
      isObjectTasksExpanded,
      schedule: sortedModularObjects,
      visibleChildren,
      isObjectMilestonesExpanded,
      isObjectRequirementsExpanded,
      timelineConfig,
      filteredTasks,
      isLoadingAllTasks,
      filters: variables,
      dateHeaders,
      objectRefs,
      registerRef,
      getObjectRef,
      registerLeaderLine,
      registerObjectChildrenToggleRef,
      isExpandedView,
      setIsExpandedView,
      saveViewSetting,
      getObjectChildrenToggleRef,
      updateGanttTreeVisualizations,
      isLoadingTopLevelObjects,
      collapseAll,
      registerObjectChildren,
      unregisterObjectChildren,
      getChildren,
      ...values,
    }),
    [
      scheduleView,
      setScheduleView,
      filterText,
      selectedFilters,
      toggleObject,
      timelineConfig,
      toggleObjectTasks,
      toggleObjectRequirements,
      toggleObjectMilestones,
      isObjectExpanded,
      isObjectTasksExpanded,
      sortedModularObjects,
      visibleChildren,
      isObjectMilestonesExpanded,
      isObjectRequirementsExpanded,
      filteredTasks,
      isLoadingAllTasks,
      variables,
      dateHeaders,
      objectRefs,
      registerRef,
      getObjectRef,
      registerLeaderLine,
      registerObjectChildrenToggleRef,
      isExpandedView,
      setIsExpandedView,
      saveViewSetting,
      getObjectChildrenToggleRef,
      updateGanttTreeVisualizations,
      isLoadingTopLevelObjects,
      collapseAll,
      registerObjectChildren,
      unregisterObjectChildren,
      getChildren,
      values,
    ],
  );

  return (
    <ScheduleContext.Provider value={value}>
      {children(value)}
    </ScheduleContext.Provider>
  );
}
