import {
  GANTT_CELL_WIDTH,
  GANTT_SCROLLABLE_WIDTH,
  TimelineColumnValues,
  TimelineZoomLevel,
} from '@/components/Schedule/constants';
import dayjs, { type Dayjs, type OpUnitType } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import useScrollMarkerObserver from './useScrollMarkerObserver';

export interface TimelineConfig {
  timelineView: TimelineZoomLevel;
  headerColumnValue: OpUnitType;
  unit: OpUnitType;
  timeIncrement: number;
  pivotDate: Dayjs;
  oneDayIncrement: number;
  startDate: Dayjs;
  zoom: string;
  endDate: Dayjs;
  getNextDates: () => void;
  goToTodayMarker: () => void;
  goToObjectStartDate: (startDate: Dayjs) => void;
  getPrevDates: () => void;
  setTimelineQuery: (zoom: TimelineZoomLevel) => void;
}

function setDateRange (config) {
  const { pivotDate, timeIncrement, unit } = config;

  return {
    ...config,
    startDate: pivotDate.subtract(timeIncrement, unit),
    endDate: pivotDate.add(timeIncrement, unit),
  };
}

function calculatePivotDate (date = dayjs()) {
  // * The start of the week is Sunday, we add one day to anchor the pivot date to Monday
  return date.startOf('week').add(1, 'day');
}

const TOTAL_COLUMNS = (GANTT_SCROLLABLE_WIDTH / GANTT_CELL_WIDTH) / 2;
const COLUMN_COUNT_IN_MONTHS = TOTAL_COLUMNS / 12;
const COLUMN_COUNT_IN_QUARTERS = TOTAL_COLUMNS / 4;

const DEFAULT_PIVOT_DATE = calculatePivotDate();

const weekConfig = Object.freeze({
  timelineView: TimelineZoomLevel.Week,
  headerColumnValue: TimelineColumnValues.Week as OpUnitType,
  unit: 'day',
  oneDayIncrement: GANTT_CELL_WIDTH,
  pivotDate: DEFAULT_PIVOT_DATE,
  timeIncrement: TOTAL_COLUMNS,
});

const quarterConfig = Object.freeze({
  timelineView: TimelineZoomLevel.Quarter,
  headerColumnValue: TimelineColumnValues.Quarter as OpUnitType,
  unit: 'year',
  oneDayIncrement: GANTT_CELL_WIDTH / 30.5,
  pivotDate: DEFAULT_PIVOT_DATE,
  timeIncrement: COLUMN_COUNT_IN_MONTHS,
});

const monthConfig = Object.freeze({
  timelineView: TimelineZoomLevel.Month,
  headerColumnValue: TimelineColumnValues.Month as OpUnitType,
  unit: 'week',
  oneDayIncrement: GANTT_CELL_WIDTH / 7,
  pivotDate: DEFAULT_PIVOT_DATE,
  timeIncrement: TOTAL_COLUMNS,
});

const yearConfig = Object.freeze({
  timelineView: TimelineZoomLevel.Year,
  headerColumnValue: TimelineColumnValues.Year as OpUnitType,
  unit: 'year',
  oneDayIncrement: GANTT_CELL_WIDTH / 90.5,
  pivotDate: DEFAULT_PIVOT_DATE,
  timeIncrement: COLUMN_COUNT_IN_QUARTERS,
});

const ZOOM_LEVELS = new Map();

ZOOM_LEVELS.set(TimelineZoomLevel.Week, weekConfig);
ZOOM_LEVELS.set(TimelineZoomLevel.Month, monthConfig);
ZOOM_LEVELS.set(TimelineZoomLevel.Year, yearConfig);
ZOOM_LEVELS.set(TimelineZoomLevel.Quarter, quarterConfig);

export default function useTimelineConfig (initialZoom = TimelineZoomLevel.Quarter) {
  const [
    timelineConfig,
    setTimelineConfig,
  ] = useState<TimelineConfig>(() => setDateRange(ZOOM_LEVELS.get(initialZoom)));

  useScrollMarkerObserver(timelineConfig.timelineView, setTimelineConfig);

  const setTimelineQuery = useCallback((zoom): void => {
    setTimelineConfig(prev => {
      const newPivotDate = calculatePivotDate(prev.pivotDate);
      return setDateRange({
        ...ZOOM_LEVELS.get(zoom),
        pivotDate: newPivotDate,
      });
    });
  }, []);

  const getNextDates = useCallback(() => {
    setTimelineConfig((prev) => {
      const newPivotDate = calculatePivotDate(prev.endDate);
      return setDateRange({ ...prev, pivotDate: newPivotDate });
    });
  }, []);

  const getPrevDates = useCallback(() => {
    setTimelineConfig((prev) => {
      const newPivotDate = calculatePivotDate(prev.startDate);
      return setDateRange({ ...prev, pivotDate: newPivotDate });
    });
  }, []);

  const goToTodayMarker = useCallback(() => {
    setTimelineConfig((prev) => {
      const newPivotDate = calculatePivotDate();
      return setDateRange({ ...prev, pivotDate: newPivotDate });
    });
  }, []);

  const goToObjectStartDate = useCallback((startDate: Dayjs) => {
    setTimelineConfig((prev) => {
      const newPivotDate = calculatePivotDate(startDate);
      return setDateRange({ ...prev, pivotDate: newPivotDate });
    });
  }, []);

  const config = useMemo(() => ({
    ...timelineConfig,
    getNextDates,
    getPrevDates,
    goToTodayMarker,
    goToObjectStartDate,
    setTimelineQuery,
  }), [timelineConfig]);

  return config;
}
