import type { Dependency, DependencyBlockType } from '@/__generated__/types';
import RadioInput from '@/components/form/RadioInput/RadioInput';
import { addToastError } from '@/components/Toast/utils';
import { patchDependencies, postDependencies } from '@/util/requests.functions';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { type PropsWithChildren, useMemo, useState } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import * as fixedGapAnimation from './gapGifs/fixed.json';
import * as gapAnimation from './gapGifs/gap.json';
import * as noOverlapAnimation from './gapGifs/no-overlap.json';
import * as noGapAnimation from './gapGifs/none.json';

import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

import { useObjectCardScrollContext } from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { ERROR_MESSAGES } from '@/util/constants';
import metrics from '@/util/metrics';
import toObject from 'dayjs/plugin/toObject';
import { type GetGapDataQuery, useGetGapDataQuery } from './getGapData.generated';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

dayjs.extend(toObject);

const gapTypes = [
  {
    title: 'No Overlap',
    value: 'noOverlap',
    icon: 'fa-slack-shift',
    description: 'Flexible gap until dates overlap',
  },
  {
    title: 'Fixed',
    value: 'strict',
    icon: 'fa-locked-shift-1',
    description: 'Gap distance remains rigid when dependencies shift',
  },
  {
    title: 'None',
    value: 'none',
    icon: 'fa-no-shift',
    description: 'Changes to dates have no effect on dependencies',
  },
];

interface ListItemProps {
  checkedValue: string;
  title: string;
  description: string;
  value: string;
  icon: string;
  onCheckedCallback: () => void;
}

function ListItem ({ checkedValue, title, description, value, icon, onCheckedCallback }: ListItemProps) {
  return (
    <div className='flex gap-2 justify-start items-center'>
      <RadioInput
        name='dependency-modal-item-choice'
        className='w-6 h-6'
        label={
          <span className='flex gap-3 items-center ml-2'>
            <i className={`fa-kit ${icon} text-[10px]`} />
            {title}
          </span>
        }
        value={value}
        checkedValue={checkedValue}
        onCheckedCallback={onCheckedCallback}
      />

      <div className='font-normal leading-none text-black effra-12'>
        {description}
      </div>
    </div>
  );
}

interface UserInputWidgetProps {
  title: string;
  description: string | JSX.Element;
}
function UserInputWidget ({ children, title, description }: PropsWithChildren<UserInputWidgetProps>) {
  return (
    <div className='flex flex-col gap-3'>
      <div className='leading-loose text-black effra-24'>{title}</div>
      <div className='self-stretch text-black effra-12'>
        {description}
      </div>
      <div className='inline-flex gap-4 justify-start items-center'>
        {children}
      </div>
    </div>
  );
}

const createIterationKey = (key: string) => uuidv5(key, uuidv4());

export interface EditableDependency {
  id: string;
  daysToEdit: number;
  monthsToEdit: number;
  yearsToEdit: number;
  blockTypeToEdit: DependencyBlockType;
}

interface ObjectConfigScreenProps extends StepContentProps {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  currentObjectId: string;
  blockedById: string;
  isBlockingDependency: boolean;
  dependencyIdToEdit?: Readonly<EditableDependency>;
  closeModal: () => void;
}

interface GapData {
  value: number;
  sign: number;
}

export default function ObjectConfigScreen ({
  decrementStep,
  selectedIds,
  currentObjectId,
  isBlockingDependency,
  dependencyIdToEdit,
  closeModal,
}: Readonly<ObjectConfigScreenProps>) {
  const { scrollToId } = useObjectCardScrollContext();
  const {
    daysToEdit,
    monthsToEdit,
    yearsToEdit,
    blockTypeToEdit,
  } = dependencyIdToEdit ?? {};
  const isEditMode = Boolean(dependencyIdToEdit);
  const isAddingMultipleDependencies = selectedIds.length > 1;

  const { activeModularObjectId } = useObjectCardContext();
  const apolloClient = useApolloClient();

  const [gapDays, setGapDays] = useState<GapData>({
    value: daysToEdit ?? 0,
    sign: daysToEdit > 0 ? 1 : -1,
  });
  const [gapMonths, setGapMonths] = useState<GapData>({
    value: monthsToEdit ?? 0,
    sign: monthsToEdit > 0 ? 1 : -1,
  });
  const [gapYears, setGapYears] = useState<GapData>({
    value: yearsToEdit ?? 0,
    sign: yearsToEdit > 0 ? 1 : -1,
  });

  const [
    selectedGapType,
    setSelectedGapType,
  ] = useState<DependencyBlockType>(blockTypeToEdit ?? 'noOverlap');

  const dependencyInputs = selectedIds.map(id => {
    return isBlockingDependency
      ? { modularObjectId: id, blockedById: currentObjectId }
      : { modularObjectId: currentObjectId, blockedById: id };
  });

  const getInitialGapValue = (data: GetGapDataQuery, selectedGapType: DependencyBlockType) => {
    const [gapValues] = data?.getDependencyGapCalcs ?? [];
    const gapData = gapValues[selectedGapType];
    const initialGapDays = {
      value: gapData?.gapDays ?? 0,
      sign: gapData?.gapDays > 0 ? 1 : -1,
    };

    const initialGapMonths = {
      value: gapData?.gapMonths ?? 0,
      sign: gapData?.gapMonths > 0 ? 1 : -1,
    };

    const initialGapYears = {
      value: gapData?.gapYears ?? 0,
      sign: gapData?.gapYears > 0 ? 1 : -1,
    };

    return {
      gapDays: initialGapDays,
      gapMonths: initialGapMonths,
      gapYears: initialGapYears,
    };
  };

  const setInitialGapValues = (data: GetGapDataQuery, selectedGapType: DependencyBlockType) => {
    const { gapDays, gapMonths, gapYears } = getInitialGapValue(data, selectedGapType);
    setGapDays(gapDays);
    setGapMonths(gapMonths);
    setGapYears(gapYears);
  };

  const { data: gapDataResponse } = useGetGapDataQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        dependencyInputs,
      },
    },
    skip: !currentObjectId || isEditMode,
    onCompleted: data => {
      const shouldSetInitialGap = Boolean(
        !isEditMode &&
          !isAddingMultipleDependencies,
      );
      if (!shouldSetInitialGap) return;
      setInitialGapValues(data, selectedGapType);
    },
  });

  const getPostDependenciesInput = () => {
    const gapData = gapDataResponse?.getDependencyGapCalcs ?? [];
    // When adding multiple dependencies, the user cannot set the gap
    if (isAddingMultipleDependencies) {
      return gapData.map((dependency) => {
        const gapTypeVals = selectedGapType === 'noOverlap' ? dependency.noOverlap : dependency.strict;

        return {
          gapDays: gapTypeVals.gapDays,
          gapMonths: gapTypeVals.gapMonths,
          gapYears: gapTypeVals.gapYears,
          modularObjectId: dependency.modularObjectId,
          blockedById: dependency.blockedById,
          blockType: selectedGapType,
        };
      }) as Partial<Dependency[]>;
    }

    return [{
      gapDays: gapDays.value,
      gapMonths: gapMonths.value,
      gapYears: gapYears.value,
      modularObjectId: gapData[0].modularObjectId,
      blockedById: gapData[0].blockedById,
      blockType: selectedGapType,
    }] as Partial<Dependency[]>;
  };

  const animationType = useMemo(() => {
    if (selectedGapType === 'none') return noGapAnimation;
    if (selectedGapType === 'strict') return fixedGapAnimation;
    if (selectedGapType === 'noOverlap') return noOverlapAnimation;
  }, [selectedGapType]);

  const handleAddDependencies = async () => {
    const postDependenciesInput = getPostDependenciesInput();

    metrics.track('dependency modal - add dependencies', { amountSelected: postDependenciesInput?.length });

    try {
      const response = await postDependencies(
        postDependenciesInput,
        activeModularObjectId,
      );
      // Handle errors that come back during the successful request, because partial success is possible in this request
      if (response.errors) {
        response.errors.forEach(error => {
          addToastError(error);
        });
      }
    } catch (e) {
      switch (e.error) {
        case ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.MATCHER:
          return addToastError(
            `Cannot add dependency. ${ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        case ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.MATCHER:
          return addToastError(
            `Cannot add dependency. ${ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        default:
          addToastError('Cannot add dependency. Please try again later.');
      }
    }

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: 'getModularObjectByID' });
      },
    });

    // Refetches the queries for the gantt if observable
    apolloClient.reFetchObservableQueries().catch(console.error);

    scrollToId('dependencies-section');
    closeModal();
  };

  const handleEditDependencies = async () => {
    metrics.track('dependency modal - edit dependencies');

    try {
      await patchDependencies([{
        id: dependencyIdToEdit.id,
        gapDays: gapDays.value,
        gapMonths: gapMonths.value,
        gapYears: gapYears.value,
        blockType: selectedGapType,
      }] as Partial<Dependency[]>, activeModularObjectId);
    } catch (e) {
      switch (e.error) {
        case ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.MATCHER:
          return addToastError(
            `Cannot edit dependency. ${ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        case ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.MATCHER:
          return addToastError(
            `Cannot edit dependency. ${ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        default:
          addToastError('Cannot edit dependency. Please try again later.');
      }
    }

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: 'getModularObjectByID' });
      },
    });
    // Refetches the queries for the gantt if observable
    apolloClient.reFetchObservableQueries().catch(console.error);

    scrollToId('dependencies-section');
    closeModal();
  };

  const handleDependencySave = isEditMode ? handleEditDependencies : handleAddDependencies;

  return (
    <>
      <div className='flex flex-col gap-10 w-full'>
        {/* row 1 */}
        {!isAddingMultipleDependencies && (
          <div className='flex flex-1'>
            <div className='flex flex-1'>
              <UserInputWidget
                title='Set gap'
                description={selectedGapType === 'none'
                  ? (
                    <p>
                      <i className='fa-sharp fa-regular fa-triangle-exclamation' />{' '}
                      Gap input is disabled when gap behavior is set to None
                    </p>
                  )
                  : 'Adjust the distance between the item and this dependency.'}
              >
                <i className='text-sm fa-kit fa-locked-shift' />
                <div className='inline-flex gap-2 items-center px-2 h-12 bg-white'>
                  <input
                    type='number'
                    value={Math.abs(gapYears.value)}
                    disabled={selectedGapType === 'none'}
                    placeholder=' ' // this space needs to stay here, or styling will break
                    onChange={e => {
                      setGapYears({
                        value: +e.target.value * gapYears.sign || 0,
                        sign: gapYears.sign,
                      });
                    }}
                    className='text-sm font-medium leading-none text-black font-effra input-text w-[54px]'
                  />
                  <div className='font-normal leading-3 text-stone-500 text-[10px] font-effra'>Years</div>
                </div>
                <div className='inline-flex gap-2 items-center px-2 h-12 bg-white'>
                  <input
                    type='number'
                    value={Math.abs(gapMonths.value)}
                    disabled={selectedGapType === 'none'}
                    placeholder=' ' // this space needs to stay here, or styling will break
                    onChange={e => {
                      setGapMonths({
                        value: +e.target.value * gapMonths.sign || 0,
                        sign: gapMonths.sign,
                      });
                    }}
                    className='text-sm font-medium leading-none text-black font-effra input-text w-[54px]'
                  />
                  <div className='font-normal leading-3 text-stone-500 text-[10px] font-effra'>Months</div>
                </div>
                <div className='inline-flex gap-2 items-center px-2 h-12 bg-white'>
                  <input
                    type='number'
                    value={Math.abs(gapDays.value)}
                    disabled={selectedGapType === 'none'}
                    placeholder=' ' // this space needs to stay here, or styling will break
                    onChange={e => {
                      setGapDays({
                        value: +e.target.value * gapDays.sign || 0,
                        sign: gapDays.sign,
                      });
                    }}
                    className='text-sm font-medium leading-none text-black font-effra input-text w-[54px]'
                  />
                  <div className='font-normal leading-3 text-stone-500 text-[10px] font-effra'>Days</div>
                </div>
              </UserInputWidget>
            </div>
            <div className='flex-1 max-h-[170px]'>
              <Lottie animationData={gapAnimation} className='h-full' />
            </div>
          </div>
        )}

        {/* row 2 */}
        <div className='flex'>
          <div className='flex-1'>
            <UserInputWidget
              title='Select gap behavior'
              description='Select how item dates change when influenced by a dependency.'
            >
              <div className='flex flex-col gap-2'>
                {gapTypes.map(gapType => (
                  <ListItem
                    key={createIterationKey('custom-dependency-modal-input')}
                    checkedValue={selectedGapType}
                    onCheckedCallback={() => {
                      setSelectedGapType(gapType.value as DependencyBlockType);

                      if (isEditMode) return;

                      setInitialGapValues(gapDataResponse, gapType.value as DependencyBlockType);
                    }}
                    {...gapType}
                  />
                ))}
                <p
                  className={cx('pt-1', {
                    'hidden': selectedIds.length < 2,
                  })}
                >
                  The same gap behavior will be applied to all selected items. You can change this later.
                </p>
              </div>
            </UserInputWidget>
          </div>
          <div className='flex-1 max-h-[228px]'>
            <Lottie animationData={animationType} className='h-full' />
          </div>
        </div>
      </div>

      <div className='flex gap-8 pt-8'>
        {!dependencyIdToEdit && (
          <button className='w-full btn-ghost h-[36px]' onClick={decrementStep}>
            back
          </button>
        )}
        <button
          className='w-full btn-primary h-[36px]'
          onClick={handleDependencySave}
        >
          {dependencyIdToEdit
            ? 'save changes'
            : `add ${selectedIds.length > 1 ? 'dependencies' : 'dependency'} & save`}
        </button>
      </div>
    </>
  );
}
