import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetCollaboratorListQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type GetCollaboratorListQuery = { __typename?: 'Query', getModularObjectByIDs: Array<{ __typename?: 'ModularObject', id: string, visibility: string | null, approvalsEnabled: boolean | null, anonymousUsersSharedTo: Array<{ __typename?: 'AnonymousUser', id: string, email: string }> | null, externalLink: { __typename?: 'ObjectToActivateLink', url: string | null, status: string | null, password: string | null, access: string | null } | null, owner: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, fullName: string | null, profileImage: string | null, organizationId: string | null, deletedAt: any | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, role: Types.ShareRole | null, user: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, fullName: string | null, title: string | null, profileImage: string | null, organizationId: string | null, deletedAt: any | null } | null, teams: Array<{ __typename?: 'Team', id: string, name: string }> | null }> | null, teamShares: Array<{ __typename?: 'TeamShare', id: string, defaultRole: Types.ShareRole, team: { __typename?: 'Team', id: string, name: string, organization: { __typename?: 'Organization', id: string, name: string | null }, teamMembers: Array<{ __typename?: 'TeamMember', id: string, userId: string }> } | null }> | null }> | null };


export const GetCollaboratorListDocument = gql`
    query GetCollaboratorList($ids: [UUID!]!) {
  getModularObjectByIDs(ids: $ids) {
    id
    visibility
    anonymousUsersSharedTo {
      id
      email
    }
    externalLink {
      url
      status
      password
      access
    }
    approvalsEnabled
    owner(includeDeleted: true) {
      id
      email
      firstName
      lastName
      fullName
      profileImage
      organizationId
      deletedAt
    }
    shares {
      id
      user {
        id
        email
        firstName
        lastName
        fullName
        title
        profileImage
        organizationId
        deletedAt
      }
      role
      teams {
        id
        name
      }
    }
    teamShares {
      id
      team {
        id
        name
        organization {
          id
          name
        }
        teamMembers {
          id
          userId
        }
      }
      defaultRole
    }
  }
}
    `;

/**
 * __useGetCollaboratorListQuery__
 *
 * To run a query within a React component, call `useGetCollaboratorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaboratorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaboratorListQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCollaboratorListQuery(baseOptions: Apollo.QueryHookOptions<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>(GetCollaboratorListDocument, options);
      }
export function useGetCollaboratorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>(GetCollaboratorListDocument, options);
        }
export function useGetCollaboratorListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>(GetCollaboratorListDocument, options);
        }
export type GetCollaboratorListQueryHookResult = ReturnType<typeof useGetCollaboratorListQuery>;
export type GetCollaboratorListLazyQueryHookResult = ReturnType<typeof useGetCollaboratorListLazyQuery>;
export type GetCollaboratorListSuspenseQueryHookResult = ReturnType<typeof useGetCollaboratorListSuspenseQuery>;
export type GetCollaboratorListQueryResult = Apollo.QueryResult<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCollaboratorListQuery((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByIDs })
 *   )
 * })
 */
export const mockGetCollaboratorListQuery = (resolver: Parameters<typeof graphql.query<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>>[1]) =>
  graphql.query<GetCollaboratorListQuery, GetCollaboratorListQueryVariables>(
    'GetCollaboratorList',
    resolver
  )
