import { useEditContext } from '@/components';
import { OnOffToggle } from '@/components/modals/CollaboratorDiscovery/AccessSettings/ToggleOption';
import DisplayValue from '@/components/modules/DisplayValue';
import { env } from '@/util/env.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faTimer } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import DetailItem from './DetailItem';

// TODO: remove this after the new duration component is implemented
function HideForProduction ({ children }: { children: React.ReactNode }): JSX.Element {
  const envs = ['production', 'staging'];

  if (envs.includes(env('NEXT_PUBLIC_STAGE'))) {
    return null;
  }

  return <>{children}</>;
}

export default function ObjectDuration (): JSX.Element {
  const [isLocked, setIsLocked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [duration, setDuration] = useState(null);
  const { canUserEdit } = useEditContext();
  const inputRef = useRef<HTMLInputElement>(null);

  // Effect to adjust the input width based on the content
  useEffect(() => {
    if (inputRef.current) {
      const length = duration?.length;
      const newWidth = Math.max(10, length * 8); // Minimum width of 50px
      duration ? inputRef.current.style.width = `${newWidth}px` : inputRef.current.style.width = '100%';
    }
  }, [duration]);

  if (!canUserEdit) {
    const displayValue = duration ? `${duration} days` : '—';

    return (
      <HideForProduction>
        <DetailItem label='Duration'>
          <div className='flex items-center gap-[8px]'>
            {duration && <FontAwesomeIcon icon={faTimer} className='text-[14px]' />}
            <DisplayValue value={displayValue} />
          </div>
        </DetailItem>
      </HideForProduction>
    );
  }

  return (
    <HideForProduction>
      <DetailItem label='Duration'>
        <div
          className={cx(
            'flex items-center pl-[5px] h-[32px] gap-[8px] w-[15rem] relative effra-14 border cursor-text text-neutral-400 group/duration-input',
            {
              '!text-black border-transparent': duration,
              'hover:border-b-black hover:border-neutral-200': !isFocused,
              '!border-neutral-200 !border-b-primary': isFocused,
              'border-neutral-200': !duration,
            },
          )}
          onClick={() => {
            setIsFocused(true);
            inputRef.current?.focus();
          }}
        >
          <FontAwesomeIcon icon={faTimer} className='text-[14px]' />
          <div className='flex items-center'>
            <input
              type='text'
              placeholder='Enter duration'
              ref={inputRef}
              value={duration}
              className='h-full outline-none min-w-[1ch] placeholder-neutral-400'
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 6) {
                  setDuration(newValue);
                }
              }}
              onBlur={() => setIsFocused(false)}
              maxLength={6}
            />
            {duration && <span className='ml-1 align-baseline mt-[2px]'>days</span>}
          </div>
          {duration && (
            <OnOffToggle
              identifier='object-duration'
              enabled={isLocked}
              onChange={() => setIsLocked(!isLocked)}
              onState={
                <span className='flex justify-center items-center h-full'>
                  <FontAwesomeIcon icon={faLock} className='text-[8px]' />
                </span>
              }
              offState={
                <span className='flex justify-center items-center h-full'>
                  <FontAwesomeIcon icon={faLockOpen} className='text-[8px]' />
                </span>
              }
            />
          )}
        </div>
      </DetailItem>
    </HideForProduction>
  );
}
