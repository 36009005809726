import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DeleteTeamShareMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteTeamShareMutation = { __typename?: 'Mutation', deleteTeamShare: { __typename?: 'TeamShare', id: string } };


export const DeleteTeamShareDocument = gql`
    mutation DeleteTeamShare($id: UUID!) {
  deleteTeamShare(id: $id) {
    id
  }
}
    `;
export type DeleteTeamShareMutationFn = Apollo.MutationFunction<DeleteTeamShareMutation, DeleteTeamShareMutationVariables>;

/**
 * __useDeleteTeamShareMutation__
 *
 * To run a mutation, you first call `useDeleteTeamShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamShareMutation, { data, loading, error }] = useDeleteTeamShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamShareMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamShareMutation, DeleteTeamShareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamShareMutation, DeleteTeamShareMutationVariables>(DeleteTeamShareDocument, options);
      }
export type DeleteTeamShareMutationHookResult = ReturnType<typeof useDeleteTeamShareMutation>;
export type DeleteTeamShareMutationResult = Apollo.MutationResult<DeleteTeamShareMutation>;
export type DeleteTeamShareMutationOptions = Apollo.BaseMutationOptions<DeleteTeamShareMutation, DeleteTeamShareMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteTeamShareMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deleteTeamShare })
 *   )
 * })
 */
export const mockDeleteTeamShareMutation = (resolver: Parameters<typeof graphql.mutation<DeleteTeamShareMutation, DeleteTeamShareMutationVariables>>[1]) =>
  graphql.mutation<DeleteTeamShareMutation, DeleteTeamShareMutationVariables>(
    'DeleteTeamShare',
    resolver
  )
