interface XY {
  x: number;
  y: number;
}

/**
 * Handles adding a radius to the svg path
 * Function sourced from https://github.com/anseki/leader-line/issues/28#issuecomment-425181063
 * @param pathData svg path data (d attribute from the path element)
 * @param radius radius to add to the path
 * @returns
 */
export function addArc (pathData, radius: number) {
  const reL = /^L ?([\d.\-+]+) ([\d.\-+]+) ?/;
  let newPathData: string;
  let curXY: XY;
  let curDir;
  let newXY: XY;
  let newDir;
  let sweepFlag: string;
  let arcXY: XY;
  let arcStartXY: XY;

  function getDir (xy1, xy2) {
    if (xy1.x === xy2.x) {
      return xy1.y < xy2.y ? 'd' : 'u';
    } else if (xy1.y === xy2.y) {
      return xy1.x < xy2.x ? 'r' : 'l';
    }
    throw new Error('Invalid data');
  }

  function captureXY (s, x, y) {
    newXY = { x: +x, y: +y };
    return '';
  }

  function offsetXY (xy: XY, dir, offsetLen, toBack?) {
    return {
      x: xy.x + (dir === 'l' ? -offsetLen : dir === 'r' ? offsetLen : 0) * (toBack ? -1 : 1),
      y: xy.y + (dir === 'u' ? -offsetLen : dir === 'd' ? offsetLen : 0) * (toBack ? -1 : 1),
    };
  }

  pathData = pathData.trim().replace(/,/g, ' ').replace(/\s+/g, ' ')
    .replace(/^M ?([\d.\-+]+) ([\d.\-+]+) ?/, function (s, x, y) {
      curXY = { x: +x, y: +y };
      return '';
    });
  if (!curXY) throw new Error('Invalid data');
  newPathData = `M${curXY.x} ${curXY.y}`;

  while (pathData) {
    newXY = null;
    pathData = pathData.replace(reL, captureXY);
    if (!newXY) throw new Error('Invalid data');

    newDir = getDir(curXY, newXY);
    if (curDir) {
      arcStartXY = offsetXY(curXY, curDir, radius, true);
      arcXY = offsetXY(curXY, newDir, radius);
      sweepFlag = curDir === 'l' && newDir === 'u' ?
        '1' :
        curDir === 'l' && newDir === 'd' ?
        '0' :
        curDir === 'r' && newDir === 'u' ?
        '0' :
        curDir === 'r' && newDir === 'd' ?
        '1' :
        curDir === 'u' && newDir === 'l' ?
        '0' :
        curDir === 'u' && newDir === 'r' ?
        '1' :
        curDir === 'd' && newDir === 'l' ?
        '1' :
        curDir === 'd' && newDir === 'r' ?
        '0' :
        null;
      if (!sweepFlag) throw new Error('Invalid data');
      newPathData += `L${arcStartXY.x} ${arcStartXY.y}A ${radius} ${radius} 0 0 ${sweepFlag} ${arcXY.x} ${arcXY.y}`;
    }

    curXY = newXY;
    curDir = newDir;
  }
  newPathData += `L${curXY.x} ${curXY.y}`;
  return newPathData;
}
