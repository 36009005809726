import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { TimelineZoomLevel } from '../../constants';

export default function useScrollMarkerObserver (
  timelineView: TimelineZoomLevel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTimelineConfig: (config: any) => void,
) {
  const observerRef = useRef(null);
  const initialScrollLeft = useRef<number | null>(null);

  useEffect(() => {
    const chartContainer = document.querySelector('[data-scrollable-chart]');
    const observableMarkers = document.querySelectorAll('[data-scroll-marker]');

    initialScrollLeft.current = chartContainer?.scrollLeft;

    const chartLeftBoundary = chartContainer?.getBoundingClientRect().left ?? 0;
    const observerOptions = {
      rootMargin: `0px 0px 0px -${chartLeftBoundary + 60}px`,
      threshold: 1,
    };
    function onIntersect (entries) {
      const visibleEntries = entries.filter((entry) => entry.isIntersecting);
      const visibleEntry = visibleEntries.length > 1
        ? visibleEntries[Math.floor(visibleEntries.length / 2)]
        : visibleEntries[0];

      if (!visibleEntry || initialScrollLeft.current === chartContainer.scrollLeft) return;

      const [month, year] = visibleEntry?.target.getAttribute('data-scroll-marker').split('-') ?? [];

      const visibleDate = dayjs().month(month).year(year).startOf('month');
      const newPivotDate = timelineView === TimelineZoomLevel.Year
        ? visibleDate.startOf('quarter')
        : visibleDate;

      setTimelineConfig(prev => ({ ...prev, pivotDate: newPivotDate }));
    }

    observerRef.current = new IntersectionObserver(
      onIntersect,
      observerOptions,
    );

    // Start observing the target element
    const markers = Array
      .from(observableMarkers);

    for (const date of markers) {
      observerRef.current.observe(date);
    }

    return () => {
      // Stop observing the target element when the component unmounts
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  });
}
