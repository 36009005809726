import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ObjectListRowPropsFragmentDoc } from '../../AddNewObjectModal/ObjectListRow/ObjectListRowProps.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetModularObjectsForDependencySelectionQueryVariables = Types.Exact<{
  input: Types.GetDependencyCandidatesForObjectIdInput;
}>;


export type GetModularObjectsForDependencySelectionQuery = { __typename?: 'Query', getDependencyCandidatesForObjectId: Array<{ __typename?: 'ModularObject', id: string, name: string | null, deactivated: boolean | null, ownerId: string | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null } | null, owner: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, createdAt: any | null, externalId: string | null, externalType: string | null, modifiedAt: any | null, role: Types.ShareRole | null, userId: string | null }> | null }> | null };


export const GetModularObjectsForDependencySelectionDocument = gql`
    query GetModularObjectsForDependencySelection($input: GetDependencyCandidatesForObjectIdInput!) {
  getDependencyCandidatesForObjectId(input: $input) {
    ...ObjectListRowProps
  }
}
    ${ObjectListRowPropsFragmentDoc}`;

/**
 * __useGetModularObjectsForDependencySelectionQuery__
 *
 * To run a query within a React component, call `useGetModularObjectsForDependencySelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModularObjectsForDependencySelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModularObjectsForDependencySelectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetModularObjectsForDependencySelectionQuery(baseOptions: Apollo.QueryHookOptions<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>(GetModularObjectsForDependencySelectionDocument, options);
      }
export function useGetModularObjectsForDependencySelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>(GetModularObjectsForDependencySelectionDocument, options);
        }
export function useGetModularObjectsForDependencySelectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>(GetModularObjectsForDependencySelectionDocument, options);
        }
export type GetModularObjectsForDependencySelectionQueryHookResult = ReturnType<typeof useGetModularObjectsForDependencySelectionQuery>;
export type GetModularObjectsForDependencySelectionLazyQueryHookResult = ReturnType<typeof useGetModularObjectsForDependencySelectionLazyQuery>;
export type GetModularObjectsForDependencySelectionSuspenseQueryHookResult = ReturnType<typeof useGetModularObjectsForDependencySelectionSuspenseQuery>;
export type GetModularObjectsForDependencySelectionQueryResult = Apollo.QueryResult<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetModularObjectsForDependencySelectionQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ getDependencyCandidatesForObjectId })
 *   )
 * })
 */
export const mockGetModularObjectsForDependencySelectionQuery = (resolver: Parameters<typeof graphql.query<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>>[1]) =>
  graphql.query<GetModularObjectsForDependencySelectionQuery, GetModularObjectsForDependencySelectionQueryVariables>(
    'GetModularObjectsForDependencySelection',
    resolver
  )
