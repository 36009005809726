import type LeaderLine from 'leader-line-new';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

export const useLeaderLines = () => {
  const leaderLineLib = useRef<typeof LeaderLine | null>(null);

  const _leaderLineLib = leaderLineLib.current;
  const leaderLines = useRef<Record<string, LeaderLine>>({});
  const _leaderLines = leaderLines.current;
  const newLeaderLine = useRef<
    (
      start: Element | LeaderLine.AnchorAttachment,
      end: Element | LeaderLine.AnchorAttachment,
      options?: LeaderLine.Options,
    ) => LeaderLine | null
  >(null);

  const _createLeaderLine = useCallback(
    (
      start: Element | LeaderLine.AnchorAttachment,
      end: Element | LeaderLine.AnchorAttachment,
      options?: LeaderLine.Options,
    ) => {
      const leaderLine = newLeaderLine.current?.(start, end, options);
      return leaderLine;
    },
    [],
  );

  useLayoutEffect(() => {
    void import('leader-line-new').then(({ default: LeaderLine }) => {
      leaderLineLib.current = LeaderLine;
      newLeaderLine.current = (
        start: Element | LeaderLine.AnchorAttachment,
        end: Element | LeaderLine.AnchorAttachment,
        options: LeaderLine.Options,
      ) => new LeaderLine(start, end, options);
    });
  }, []);

  const addLeaderLine = useCallback(
    (
      start: Element | LeaderLine.AnchorAttachment,
      end: Element | LeaderLine.AnchorAttachment,
      options?: LeaderLine.Options,
      id = uuid(),
    ) => {
      const leaderLine = _createLeaderLine(start, end, options);
      _leaderLines[id] = leaderLine;

      return leaderLine;
    },
    [_createLeaderLine, _leaderLines],
  );

  const reDrawLeaderLines = useCallback(() => {
    Object.values(_leaderLines).forEach((leaderLine) => {
      leaderLine.position();
    });
  }, [_leaderLines]);

  const removeLeaderLine = useCallback(
    (id: string) => {
      _leaderLines[id]?.remove();
      delete _leaderLines[id];
    },
    [_leaderLines],
  );

  const removeAllLeaderLines = useCallback(() => {
    Object.keys(_leaderLines).forEach((id) => {
      removeLeaderLine(id);
    });
  }, [removeLeaderLine, _leaderLines]);

  const api = useMemo(() => {
    return {
      /** Reference to the LeaderLine library */
      leaderLineLib: _leaderLineLib,
      addLeaderLine,
      reDrawLeaderLines,
      removeLeaderLine,
      removeAllLeaderLines,
    };
  }, [_leaderLineLib, addLeaderLine, reDrawLeaderLines, removeAllLeaderLines, removeLeaderLine]);

  return api;
};
